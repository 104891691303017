
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';

export default defineComponent({
  emits: ['intersect'],
  setup(props, { emit }) {
    let observer;

    const observerElement = ref(null);

    onMounted(() => {
      observer = new IntersectionObserver(([entry]) => {
        if (entry && entry.isIntersecting) {
          emit('intersect');
        }
      });

      observer.observe(observerElement.value);
    });

    onUnmounted(() => {
      observer.disconnect();
    });

    return { observerElement };
  },
});
