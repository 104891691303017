
import { transformToStandardDate } from '@/composables/storyblokResultParser';
import { de } from 'date-fns/locale';
import { computed, ComputedRef, defineComponent, PropType } from 'vue';
import { AuthorModel } from '@/models/author.model';
import ArticleAuthors from '@/components/ui/ArticleAuthors.vue';
import uslug from 'uslug';
import AppCard from '@/components/ui/AppCard.vue';
import IconArrow from '@/components/ui/icons/IconArrow.vue';
import { formatDistanceToNowStrict } from 'date-fns';

export default defineComponent({
  components: { ArticleAuthors, IconArrow, AppCard },
  props: {
    title: {
      type: String,
      default: (): string => '',
    },
    slug: {
      type: String,
      default: (): string => '',
    },
    authors: {
      type: [Array, Object] as PropType<AuthorModel | AuthorModel[]>,
      default: (): Array<AuthorModel> => [],
    },
    date: {
      type: String,
      default: (): string => '',
    },
    quickLinkText: {
      type: String,
      default: (): string => '',
    },
  },
  setup(props) {
    const urlToArticle: ComputedRef<string> = computed<string>(() => {
      return `/articles/${props.slug}`;
    });

    const urlToTherapy: ComputedRef<string> = computed<string>(() => {
      return `${urlToArticle.value}#${uslug(props.quickLinkText)}`;
    });

    const formattedDate: ComputedRef<string> = computed<string>(() => {
      if (props.date) {
        return transformToStandardDate(props.date);
      } else {
        return 'Draft';
      }
    });

    const dateDistanceFromToday: ComputedRef<string> = computed<string>(() => {
      if (props.date) {
        return formatDistanceToNowStrict(new Date(props.date), { locale: de, addSuffix: true });
      } else {
        return '';
      }
    });

    return { urlToArticle, urlToTherapy, formattedDate, dateDistanceFromToday };
  },
});
