
import { defineComponent, PropType } from 'vue';
import { ArticleModel } from '@/models/article.model';
import { StoryData } from 'storyblok-js-client';
import ArticleCard from '@/components/ui/ArticleCard.vue';
import InfiniteScrollObserver from '@/components/layout/InfiniteScrollObserver.vue';

export default defineComponent({
  components: {
    ArticleCard,
    InfiniteScrollObserver,
  },
  props: {
    articles: {
      type: Array as PropType<StoryData<ArticleModel>[]>,
      default: (): Array<StoryData<ArticleModel>[]> => [],
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: (): boolean => false,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  emits: ['fetchMore'],
  setup(props, { emit }) {
    const loadMore = async () => {
      if (!props.isLoading && (props.currentPage < props.totalPages || props.totalPages === 0)) {
        emit('fetchMore');
      }
    };

    return { loadMore };
  },
});
