<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-8 mt-4">
    <ArticleCard
      v-for="article in articles"
      :key="article.content.title"
      :slug="article.slug"
      :title="article.content.title"
      :authors="article.content.authors"
      :date="article.published_at"
      :quick-link-text="article.content.quickLinkText"
    />
  </div>
  <div v-if="isLoading" class="text-center w-100 p-2">Lade...</div>
  <InfiniteScrollObserver @intersect="loadMore" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ArticleModel } from '@/models/article.model';
import { StoryData } from 'storyblok-js-client';
import ArticleCard from '@/components/ui/ArticleCard.vue';
import InfiniteScrollObserver from '@/components/layout/InfiniteScrollObserver.vue';

export default defineComponent({
  components: {
    ArticleCard,
    InfiniteScrollObserver,
  },
  props: {
    articles: {
      type: Array as PropType<StoryData<ArticleModel>[]>,
      default: (): Array<StoryData<ArticleModel>[]> => [],
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: (): boolean => false,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  emits: ['fetchMore'],
  setup(props, { emit }) {
    const loadMore = async () => {
      if (!props.isLoading && (props.currentPage < props.totalPages || props.totalPages === 0)) {
        emit('fetchMore');
      }
    };

    return { loadMore };
  },
});
</script>
