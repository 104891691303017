import { computed, reactive, toRefs } from 'vue';

interface PaginationModel<T> {
  page: number;
  totalItems: number;
  perPage: number;
  isLoading: boolean;
  items: T[];
}

export interface FetchFnReturn<T> {
  items: T[];
  total: number;
  perPage: number;
}

export default function<T>(fetchFn: (page: number) => Promise<FetchFnReturn<T>>) {
  const state: PaginationModel<T> = reactive({
    page: 0,
    totalItems: 0,
    perPage: 0,
    items: [],
    isLoading: false,
  });

  const totalPages = computed(() => {
    if (state.perPage === 0) {
      return 0;
    }
    return Math.ceil(state.totalItems / state.perPage);
  });

  const reset = () => {
    state.totalItems = 0;
    state.perPage = 0;
    state.page = 0;
    state.items = [];
  };

  const next = async () => {
    state.isLoading = true;
    state.page++;

    const { items, total, perPage } = await fetchFn(state.page);

    state.totalItems = total;
    state.perPage = perPage;

    state.items = [...state.items, ...items];

    state.isLoading = false;
  };

  return {
    ...toRefs(state),
    reset,
    totalPages,
    next,
  };
}
