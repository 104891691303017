<template>
  <div class="card">
    <div v-if="!hideMetaInfo" class="card__meta-info">
      <slot name="card-meta-info"></slot>
    </div>
    <header v-if="!hideHeader" class="card__header">
      <slot name="card-header"></slot>
    </header>
    <div v-if="!hideImage" class="card__image">
      <slot name="card-image"></slot>
    </div>
    <div class="px-4 pb-2">
      <slot name="card-body"></slot>
    </div>
    <footer v-if="!hideFooter" class="card__footer">
      <slot name="card-footer"></slot>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    hideHeader: {
      type: Boolean,
      default: (): boolean => false,
    },
    hideMetaInfo: {
      type: Boolean,
      default: (): boolean => false,
    },
    hideFooter: {
      type: Boolean,
      default: (): boolean => false,
    },
    hideImage: {
      type: Boolean,
      default: (): boolean => false,
    },
  },
});
</script>

<style scoped>
.card {
  @apply rounded-lg overflow-hidden shadow-lg border-2 border-brand-500;
}

.card__header {
  @apply text-xl font-bold px-4 py-2;
}

.card__meta-info {
  @apply text-gray-500 leading-snug text-xs font-bold pt-4 px-4;
}

.card__image {
  @apply flex justify-center pb-4;
}

.card__footer {
  @apply flex items-center border-t border-gray-400 p-4 font-bold text-xs leading-snug;
}
</style>
