
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    hideHeader: {
      type: Boolean,
      default: (): boolean => false,
    },
    hideMetaInfo: {
      type: Boolean,
      default: (): boolean => false,
    },
    hideFooter: {
      type: Boolean,
      default: (): boolean => false,
    },
    hideImage: {
      type: Boolean,
      default: (): boolean => false,
    },
  },
});
